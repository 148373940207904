import React, { useState } from 'react';
import './App.css';
import axios from 'axios';
import ImageUpload from './components/ImageUpload';

function App() {
    const [image, setImage] = useState(null);
    const [receiptData, setReceiptData] = useState([]);
    const [error, setError] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);

    const handleImageUpload = (file) => {
        setImage(file);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!image) {
            alert('Please upload or capture an image first.');
            return;
        }

        const formData = new FormData();
        formData.append('image', image);

        try {
            setIsProcessing(true);

            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(`${apiUrl}/receipt/upload`, formData, {
                headers: {
                'Content-Type': 'multipart/form-data',
                },
            });
            const responseData = response.data;
            if (responseData.data.items.length > 0) {
                setReceiptData(responseData.data);
                setError('');
            } else {
                setError('Failed to fetch receipt data');
            }
        } catch (error) {
            console.error('Error submitting image:', error);
        } finally {
            setIsProcessing(false);
        }
    };

  return (
        <div className="App">
            <h1>Receipt Scanner</h1>
            <form onSubmit={handleSubmit}>
                <div>
                <h3>Upload Image</h3>
                <ImageUpload onImageUpload={handleImageUpload} />
                </div>
                <button type="submit" disabled={isProcessing}>{isProcessing ? 'Processing...' : 'Submit'}</button>
            </form>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {receiptData?.items?.length > 0 && (
                <table border="1" style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                    <tr>
                        {Object.keys(receiptData.items[0]).map((key) => (
                        <th key={key}>{key}</th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {receiptData.items.map((item, index) => (
                        <tr key={index}>
                        {Object.values(item).map((value, idx) => (
                            <td key={idx}>{value}</td>
                        ))}
                        </tr>
                    ))}
                    </tbody>
                </table>
            )}
        </div>
    );
}

export default App;
